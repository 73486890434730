/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as AuthImport } from './routes/auth'
import { Route as ProtectedImport } from './routes/_protected'
import { Route as LayoutImport } from './routes/_layout'
import { Route as LayoutIndexImport } from './routes/_layout.index'
import { Route as AuthRegisterImport } from './routes/auth/register'
import { Route as AuthLoginImport } from './routes/auth/login'
import { Route as AuthForgetPasswordImport } from './routes/auth/forget-password'
import { Route as ProtectedUploaderImport } from './routes/_protected/uploader'
import { Route as ProtectedTodosImport } from './routes/_protected/todos'
import { Route as ProtectedHealthImport } from './routes/_protected/health'
import { Route as ProtectedTodosIdImport } from './routes/_protected/todos.$id'

// Create/Update Routes

const AuthRoute = AuthImport.update({
  id: '/auth',
  path: '/auth',
  getParentRoute: () => rootRoute,
} as any)

const ProtectedRoute = ProtectedImport.update({
  id: '/_protected',
  getParentRoute: () => rootRoute,
} as any)

const LayoutRoute = LayoutImport.update({
  id: '/_layout',
  getParentRoute: () => rootRoute,
} as any)

const LayoutIndexRoute = LayoutIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => LayoutRoute,
} as any)

const AuthRegisterRoute = AuthRegisterImport.update({
  id: '/register',
  path: '/register',
  getParentRoute: () => AuthRoute,
} as any)

const AuthLoginRoute = AuthLoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => AuthRoute,
} as any)

const AuthForgetPasswordRoute = AuthForgetPasswordImport.update({
  id: '/forget-password',
  path: '/forget-password',
  getParentRoute: () => AuthRoute,
} as any)

const ProtectedUploaderRoute = ProtectedUploaderImport.update({
  id: '/uploader',
  path: '/uploader',
  getParentRoute: () => ProtectedRoute,
} as any)

const ProtectedTodosRoute = ProtectedTodosImport.update({
  id: '/todos',
  path: '/todos',
  getParentRoute: () => ProtectedRoute,
} as any)

const ProtectedHealthRoute = ProtectedHealthImport.update({
  id: '/health',
  path: '/health',
  getParentRoute: () => ProtectedRoute,
} as any)

const ProtectedTodosIdRoute = ProtectedTodosIdImport.update({
  id: '/$id',
  path: '/$id',
  getParentRoute: () => ProtectedTodosRoute,
} as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_layout': {
      id: '/_layout'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof LayoutImport
      parentRoute: typeof rootRoute
    }
    '/_protected': {
      id: '/_protected'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof ProtectedImport
      parentRoute: typeof rootRoute
    }
    '/auth': {
      id: '/auth'
      path: '/auth'
      fullPath: '/auth'
      preLoaderRoute: typeof AuthImport
      parentRoute: typeof rootRoute
    }
    '/_protected/health': {
      id: '/_protected/health'
      path: '/health'
      fullPath: '/health'
      preLoaderRoute: typeof ProtectedHealthImport
      parentRoute: typeof ProtectedImport
    }
    '/_protected/todos': {
      id: '/_protected/todos'
      path: '/todos'
      fullPath: '/todos'
      preLoaderRoute: typeof ProtectedTodosImport
      parentRoute: typeof ProtectedImport
    }
    '/_protected/uploader': {
      id: '/_protected/uploader'
      path: '/uploader'
      fullPath: '/uploader'
      preLoaderRoute: typeof ProtectedUploaderImport
      parentRoute: typeof ProtectedImport
    }
    '/auth/forget-password': {
      id: '/auth/forget-password'
      path: '/forget-password'
      fullPath: '/auth/forget-password'
      preLoaderRoute: typeof AuthForgetPasswordImport
      parentRoute: typeof AuthImport
    }
    '/auth/login': {
      id: '/auth/login'
      path: '/login'
      fullPath: '/auth/login'
      preLoaderRoute: typeof AuthLoginImport
      parentRoute: typeof AuthImport
    }
    '/auth/register': {
      id: '/auth/register'
      path: '/register'
      fullPath: '/auth/register'
      preLoaderRoute: typeof AuthRegisterImport
      parentRoute: typeof AuthImport
    }
    '/_layout/': {
      id: '/_layout/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof LayoutIndexImport
      parentRoute: typeof LayoutImport
    }
    '/_protected/todos/$id': {
      id: '/_protected/todos/$id'
      path: '/$id'
      fullPath: '/todos/$id'
      preLoaderRoute: typeof ProtectedTodosIdImport
      parentRoute: typeof ProtectedTodosImport
    }
  }
}

// Create and export the route tree

interface LayoutRouteChildren {
  LayoutIndexRoute: typeof LayoutIndexRoute
}

const LayoutRouteChildren: LayoutRouteChildren = {
  LayoutIndexRoute: LayoutIndexRoute,
}

const LayoutRouteWithChildren =
  LayoutRoute._addFileChildren(LayoutRouteChildren)

interface ProtectedTodosRouteChildren {
  ProtectedTodosIdRoute: typeof ProtectedTodosIdRoute
}

const ProtectedTodosRouteChildren: ProtectedTodosRouteChildren = {
  ProtectedTodosIdRoute: ProtectedTodosIdRoute,
}

const ProtectedTodosRouteWithChildren = ProtectedTodosRoute._addFileChildren(
  ProtectedTodosRouteChildren,
)

interface ProtectedRouteChildren {
  ProtectedHealthRoute: typeof ProtectedHealthRoute
  ProtectedTodosRoute: typeof ProtectedTodosRouteWithChildren
  ProtectedUploaderRoute: typeof ProtectedUploaderRoute
}

const ProtectedRouteChildren: ProtectedRouteChildren = {
  ProtectedHealthRoute: ProtectedHealthRoute,
  ProtectedTodosRoute: ProtectedTodosRouteWithChildren,
  ProtectedUploaderRoute: ProtectedUploaderRoute,
}

const ProtectedRouteWithChildren = ProtectedRoute._addFileChildren(
  ProtectedRouteChildren,
)

interface AuthRouteChildren {
  AuthForgetPasswordRoute: typeof AuthForgetPasswordRoute
  AuthLoginRoute: typeof AuthLoginRoute
  AuthRegisterRoute: typeof AuthRegisterRoute
}

const AuthRouteChildren: AuthRouteChildren = {
  AuthForgetPasswordRoute: AuthForgetPasswordRoute,
  AuthLoginRoute: AuthLoginRoute,
  AuthRegisterRoute: AuthRegisterRoute,
}

const AuthRouteWithChildren = AuthRoute._addFileChildren(AuthRouteChildren)

export interface FileRoutesByFullPath {
  '': typeof ProtectedRouteWithChildren
  '/auth': typeof AuthRouteWithChildren
  '/health': typeof ProtectedHealthRoute
  '/todos': typeof ProtectedTodosRouteWithChildren
  '/uploader': typeof ProtectedUploaderRoute
  '/auth/forget-password': typeof AuthForgetPasswordRoute
  '/auth/login': typeof AuthLoginRoute
  '/auth/register': typeof AuthRegisterRoute
  '/': typeof LayoutIndexRoute
  '/todos/$id': typeof ProtectedTodosIdRoute
}

export interface FileRoutesByTo {
  '': typeof ProtectedRouteWithChildren
  '/auth': typeof AuthRouteWithChildren
  '/health': typeof ProtectedHealthRoute
  '/todos': typeof ProtectedTodosRouteWithChildren
  '/uploader': typeof ProtectedUploaderRoute
  '/auth/forget-password': typeof AuthForgetPasswordRoute
  '/auth/login': typeof AuthLoginRoute
  '/auth/register': typeof AuthRegisterRoute
  '/': typeof LayoutIndexRoute
  '/todos/$id': typeof ProtectedTodosIdRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_layout': typeof LayoutRouteWithChildren
  '/_protected': typeof ProtectedRouteWithChildren
  '/auth': typeof AuthRouteWithChildren
  '/_protected/health': typeof ProtectedHealthRoute
  '/_protected/todos': typeof ProtectedTodosRouteWithChildren
  '/_protected/uploader': typeof ProtectedUploaderRoute
  '/auth/forget-password': typeof AuthForgetPasswordRoute
  '/auth/login': typeof AuthLoginRoute
  '/auth/register': typeof AuthRegisterRoute
  '/_layout/': typeof LayoutIndexRoute
  '/_protected/todos/$id': typeof ProtectedTodosIdRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/auth'
    | '/health'
    | '/todos'
    | '/uploader'
    | '/auth/forget-password'
    | '/auth/login'
    | '/auth/register'
    | '/'
    | '/todos/$id'
  fileRoutesByTo: FileRoutesByTo
  to:
    | ''
    | '/auth'
    | '/health'
    | '/todos'
    | '/uploader'
    | '/auth/forget-password'
    | '/auth/login'
    | '/auth/register'
    | '/'
    | '/todos/$id'
  id:
    | '__root__'
    | '/_layout'
    | '/_protected'
    | '/auth'
    | '/_protected/health'
    | '/_protected/todos'
    | '/_protected/uploader'
    | '/auth/forget-password'
    | '/auth/login'
    | '/auth/register'
    | '/_layout/'
    | '/_protected/todos/$id'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  LayoutRoute: typeof LayoutRouteWithChildren
  ProtectedRoute: typeof ProtectedRouteWithChildren
  AuthRoute: typeof AuthRouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  LayoutRoute: LayoutRouteWithChildren,
  ProtectedRoute: ProtectedRouteWithChildren,
  AuthRoute: AuthRouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_layout",
        "/_protected",
        "/auth"
      ]
    },
    "/_layout": {
      "filePath": "_layout.tsx",
      "children": [
        "/_layout/"
      ]
    },
    "/_protected": {
      "filePath": "_protected.tsx",
      "children": [
        "/_protected/health",
        "/_protected/todos",
        "/_protected/uploader"
      ]
    },
    "/auth": {
      "filePath": "auth.tsx",
      "children": [
        "/auth/forget-password",
        "/auth/login",
        "/auth/register"
      ]
    },
    "/_protected/health": {
      "filePath": "_protected/health.tsx",
      "parent": "/_protected"
    },
    "/_protected/todos": {
      "filePath": "_protected/todos.tsx",
      "parent": "/_protected",
      "children": [
        "/_protected/todos/$id"
      ]
    },
    "/_protected/uploader": {
      "filePath": "_protected/uploader.tsx",
      "parent": "/_protected"
    },
    "/auth/forget-password": {
      "filePath": "auth/forget-password.tsx",
      "parent": "/auth"
    },
    "/auth/login": {
      "filePath": "auth/login.tsx",
      "parent": "/auth"
    },
    "/auth/register": {
      "filePath": "auth/register.tsx",
      "parent": "/auth"
    },
    "/_layout/": {
      "filePath": "_layout.index.tsx",
      "parent": "/_layout"
    },
    "/_protected/todos/$id": {
      "filePath": "_protected/todos.$id.tsx",
      "parent": "/_protected/todos"
    }
  }
}
ROUTE_MANIFEST_END */
